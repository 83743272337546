<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
    data: () => {
        return {
            title: "Meus pedidos",
            items: [
                {
                    text: "Genesis",
                    href: "/",
                },
                {
                    text: "Pedidos",
                    active: true,
                },
            ],

            pedidos: [
                {
                    id: 1,
                    status: 'pendente',
                    created_at: '01/01/2021',
                    codigo: "2024010114002313123",
                    qtd: 50,
                    valor: 10000,
                    valor_total: 10100,
                    parcelas: 10,
                    valor_parcela: 1010,
                    endereco_entrega: {
                        rua: 'Rua teste',
                        numero: '123',
                        bairro: 'Bairro teste',
                        cidade: 'Cidade teste',
                        estado: 'Estado teste',
                        cep: '12345678',
                    },
                    fase1: true,
                    fase2: true,
                    fase3: true,
                    fase4: false,

                },
                {
                    id: 1,
                    status: 'ativo',
                    created_at: '01/01/2021',
                    codigo: "2024010114002313123",
                    qtd: 50,
                    valor: 10000,
                    valor_total: 10100,
                    parcelas: 10,
                    valor_parcela: 1010,
                    endereco_entrega: {
                        rua: 'Rua teste',
                        numero: '123',
                        bairro: 'Bairro teste',
                        cidade: 'Cidade teste',
                        estado: 'Estado teste',
                        cep: '12345678',
                    },

                    fase1: true,
                    fase2: true,
                    fase3: true,
                    fase4: true,
                },
                {
                    id: 1,
                    status: 'cancelado',
                    created_at: '01/01/2021',
                    codigo: "2024010114002313123",
                    qtd: 50,
                    valor: 10000,
                    valor_total: 10100,
                    parcelas: 10,
                    valor_parcela: 1010,
                    endereco_entrega: {
                        rua: 'Rua teste',
                        numero: '123',
                        bairro: 'Bairro teste',
                        cidade: 'Cidade teste',
                        estado: 'Estado teste',
                        cep: '12345678',
                    },

                    fase1: false,
                    fase2: false,
                    fase3: false,
                    fase4: false,
                },
            ]

        }
    },
    components: {Layout, PageHeader},
    methods: {
        formatCurrentBrl(value) {
            return value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
        },
    }
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h1 class="float-left">{{ title }}</h1>
                        <router-link to="/cliente/pedido-novo" class="btn btn-primary float-right">Novo pedido</router-link>
                    </div>
                </div>

                <br>
                <br>

                <div v-for="item in pedidos" :key="item.id">

                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-5">
                                    <span class="btn btn-warning" v-if="item.status=='pendente'">pendente</span>
                                    <span class="btn btn-success" v-if="item.status=='ativo'">concluído</span>
                                    <span class="btn btn-danger" v-if="item.status=='cancelado'">cancelado</span>
                                    <h2>Número do pedido: #{{ item.codigo }}</h2>
                                    <p><strong>Data do pedido:</strong> {{ item.created_at }}<br>
                                        <strong>Quantidade de itens:</strong> {{ item.qtd }}<br>
                                        <strong>Valor:</strong> {{ formatCurrentBrl(item.valor) }}<br>
                                        <strong>Valor total:</strong> {{ formatCurrentBrl(item.valor_total) }}<br>
                                        <strong>Parcelas:</strong> {{ item.parcelas }}<br>
                                        <strong>Valor da parcela:</strong> {{ formatCurrentBrl(item.valor_parcela) }}</p>

                                </div>
                                <div class="col-2">
                                    <br>
                                    <br>
                                    <h4>Endereço de entrega</h4>
                                    <p>
                                        {{ item.endereco_entrega.rua }}, {{ item.endereco_entrega.numero }}<br>
                                        {{ item.endereco_entrega.bairro }}<br>
                                        {{ item.endereco_entrega.cidade }} - {{ item.endereco_entrega.estado }}<br>
                                        {{ item.endereco_entrega.cep }}
                                    </p>

                                </div>
                                <div class="col">
                                    <br><br>
                                    <h4>Status da entrega</h4>


                                    <div class="row">

                                        <div>
                                            <div :class=" ' status-entrega ' + (item.fase1 ? 'verde':'') ">
                                                <i class="fa fa-file-archive"></i>
                                                <p>NF Gerada</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div :class=" ' status-entrega ' + (item.fase2 ? 'verde':'') ">
                                                <i class="fa fa-file-archive"></i>
                                                <p>Em separação</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div :class=" ' status-entrega ' + (item.fase3 ? 'verde':'') ">
                                                <i class="fa fa-truck"></i>
                                                <p>Em transporte</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div :class=" ' status-entrega ' + (item.fase4 ? 'verde':'') ">
                                                <i class="fa fa-check"></i>
                                                <p>Entregue</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-1">
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <br>
                                    <router-link to="/cliente/pedido" class="btn btn-block btn-dark">Ver pedido</router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </Layout>
</template>

<style>
.status-entrega {
    background: #ccc;
    border-radius: 110px;
    width: 110px;
    height: 110px;
    display: block;
    text-align: center;
    color: #fff;
    padding: 20px 5px 5px 5px;
    line-height: 100%;
    margin: 0 10px;
}

.status-entrega i {
    font-size: 40px;
    margin-bottom: 5px;
}

.status-entrega.verde {
    background: #1cbb8c;
}
</style>
